import styled, { keyframes } from 'styled-components'
import { colorsToken } from '../../../utils/style/colors'
import { Font } from '../../../utils/style/Font'

/** Styled Components **/

/**
 * Text component with white color.
 * @component
 */
const WhiteText = styled(Font.Text.TextM)`
  color: ${colorsToken.text.Invert};
  line-height: 145%; /* 1.45rem */
  margin: 0;
`

/**
 * Text component with blue color.
 * @component
 */
const BlueText = styled(Font.Text.TextM)`
  color: ${colorsToken.surface.SpecialBleu};
  line-height: 145%; /* 1.45rem */
  margin: 0;
`

/**
 * Text component with red color.
 * @component
 */
const RedText = styled(Font.Text.TextM)`
  color: ${colorsToken.surface.SpecialOrange};
  line-height: 145%; /* 1.45rem */
  margin: 0;
`

/**
 * Preformatted text container with custom margin.
 * @component
 */
const PreStyled = styled.pre`
  margin: 0; // Retire la marge par défaut
  margin-bottom: 0.1rem; // Ajoute une marge inférieure personnalisée
  color: ${colorsToken.text.Invert};
`

/**
 * Container for the entire Development section.
 * @component
 */
const DevelopmentSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colorsToken.surface.Black};
  flex: 2;
  border-radius: 2rem;
  padding: 2.5rem;
  justify-content: space-between;
  gap: 0.5rem;
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
`

/**
 * Container for the descriptive text within the Development section.
 * @component
 */
const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
`

/**
 * Container for the code display area within the Development section.
 * @component
 */
const CodeDisplayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

/**
 * Container for line numbers in the code display.
 * @component
 */
const LineNumber = styled.span`
  height: 100%;
  opacity: 0.5;
`

/**
 * Container for the code content in the display area.
 * @component
 */
const CodeContent = styled.div`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  opacity: 0.8;
`

/**
 * Container for animated text within the code.
 * @component
 */
const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

/**
 * Container for each animated line in the code.
 * @component
 */
const AnimatedLine = styled.div`
  display: flex;
`

/** Keyframes for animations **/

const typing = keyframes`
  0% { width: 0; border-right: 1px solid transparent; }
  99% { width: 99%; border-right: 1px solid}
  100% { width: 100%; border-right: none; }
`

const suppressing = keyframes`
  0% { width: 100%; border-right: 1px solid transparent; }
  99% { width: 1%; border-right: 1px solid ${colorsToken.surface.Primary}; }
  100% { width: 0%; border-right: none; }
`

const cursor = keyframes`
  50% { border-color:  ${colorsToken.surface.Primary}; }
`

/**
 * Animated text component simulating typing and suppressing animations.
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.playState - The play state of the animation.
 * @param {number} props.stepTyping - Number of steps for typing animation.
 * @param {number} props.stepSuppress - Number of steps for suppressing animation.
 * @param {string} props.duration - Duration of the animation.
 * @param {string} props.delayTyping - Delay before the typing animation starts.
 * @param {string} props.delaySuppressing - Delay before the suppressing animation starts.
 */
const AnimatedText = styled(Font.Text.TextM)`
  color: ${colorsToken.text.Invert};
  line-height: 145%; /* 1.45rem */
  margin: 0;
  border-right: 1px solid transparent;
  display: inline-block;
  margin-right: 4px;

  white-space: nowrap;
  overflow: hidden;

  animation-play-state: ${({ playState }) => playState};

  ${DevelopmentSection}:hover & {
    animation: ${typing} ${(props) => props.duration}
        steps(${(props) => props.stepTyping}, end)
        ${(props) => props.delayTyping} 1 normal both,
      ${cursor} 0.75s step-end 2.5s both;
  }

  ${DevelopmentSection}:not(:hover) & {
    animation: ${suppressing} ${(props) => props.duration}
        steps(${(props) => props.stepSuppress}, end)
        ${(props) => props.delaySuppressing} 1 normal both,
      ${cursor} 0.75s step-end 2.5s both;
  }
`

/** React Component **/

/**
 * Development component displaying animated code snippets for demonstration purposes.
 * @component
 */
function Development() {
  return (
    <DevelopmentSection>
      <TextContainer>
        <Font.Title.TitreL
          style={{ margin: '0', color: colorsToken.text.Invert }}
        >
          Développement
        </Font.Title.TitreL>
        <Font.Text.TextM
          style={{ margin: '0', color: colorsToken.text.Invert }}
        >
          Création d’interfaces interactives en React, HTML, CSS et C#.
        </Font.Text.TextM>
      </TextContainer>

      <CodeDisplayContainer>
        <LineNumber>
          <PreStyled>
            <WhiteText>
              <PreStyled>
                17
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                18
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                19
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                20
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                21
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                22
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                23
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                24
                <br />
              </PreStyled>
            </WhiteText>
            <WhiteText>
              <PreStyled>
                25
                <br />
              </PreStyled>
            </WhiteText>
          </PreStyled>
        </LineNumber>
        <CodeContent>
          <PreStyled>
            <RedText>.nav</RedText>
            <WhiteText>&#123;</WhiteText>
            <br />
          </PreStyled>
          <PreStyled>
            <WhiteText>{'     '}background: </WhiteText>
            <RedText>#</RedText>
            <BlueText>4F4D53</BlueText>
            <WhiteText>;</WhiteText>
            <br />
          </PreStyled>
          <PreStyled>
            <WhiteText>{'     '}height: </WhiteText>
            <RedText>48px</RedText>
            <WhiteText>;</WhiteText>
            <br />
          </PreStyled>
          <PreStyled>
            <WhiteText>{'     '}width: </WhiteText>
            <RedText>100%</RedText>
            <WhiteText>;</WhiteText>
            <br />
          </PreStyled>
          <PreStyled>
            <WhiteText>&#125;</WhiteText>
            <br />
          </PreStyled>
          <PreStyled>
            <RedText>.logo</RedText>
            <WhiteText>&#123;</WhiteText>
            <br />
          </PreStyled>
          <PreStyled>
            <WhiteText>{'     '}position: </WhiteText>
            <RedText>relative</RedText>
            <WhiteText>;</WhiteText>
            <br />
          </PreStyled>
          <AnimationContainer>
            <AnimatedLine style={{ marginLeft: '21px' }}>
              <div>
                <AnimatedText
                  stepTyping="19"
                  stepSuppress="2"
                  duration="1s"
                  delayTyping="0s"
                  delaySuppressing="0.7s"
                >
                  {'padding-top: '}
                </AnimatedText>
              </div>
              <div>
                <AnimatedText
                  stepTyping="5"
                  stepSuppress="1"
                  duration="0.2s"
                  delayTyping="1s"
                  delaySuppressing="0.5s"
                  style={{ color: '#FF7F42' }}
                >
                  {'10px'}
                </AnimatedText>
              </div>
              <div>
                <AnimatedText
                  stepTyping="1"
                  stepSuppress="1"
                  duration="0.1s"
                  delayTyping="1.2s"
                  delaySuppressing="0.4s"
                >
                  {';'}
                </AnimatedText>
              </div>
            </AnimatedLine>
            <AnimatedLine>
              <div>
                <AnimatedText
                  stepTyping="19"
                  stepSuppress="1"
                  duration="0.1s"
                  delayTyping="1.5s"
                  delaySuppressing="0s"
                >
                  &#125;
                </AnimatedText>
              </div>
            </AnimatedLine>
          </AnimationContainer>
        </CodeContent>
      </CodeDisplayContainer>
    </DevelopmentSection>
  )
}

export default Development
