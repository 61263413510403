import styled from 'styled-components'
import { colorsToken } from '../../../utils/style/colors'
import { Font } from '../../../utils/style/Font'
import recherchePage1 from '../../../assets/Image/Bento/Recherche/RecherchePage1.png'
import recherchePage2 from '../../../assets/Image/Bento/Recherche/RecherchePage2.png'
import { useResponsive } from '../../../utils/hooks'

const RechercheContainer = styled.div`
  flex: 1;
  border-radius: 2rem;
  background: ${colorsToken.surface.SpecialOrange};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
`

const RechercheImageContainer = styled.div`
  width: 9.6rem;
  height: 10rem;
  flex-shrink: 0;
  position: absolute;
  top: -1.51rem;
  right: 1.44rem;
`

const RechercheTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  gap: 1rem;
`

/**
 * Renvoie les styles réactifs pour une image en fonction de l'état de l'appareil
 * et des propriétés de position.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @param {number} right - Valeur de la position à droite.
 * @param {number} bottom - Valeur de la position en bas.
 * @param {number} left - Valeur de la position à gauche.
 * @param {number} top - Valeur de la position en haut.
 * @returns {string|null} Les styles CSS pour le design réactif.
 */
const getImageResponsive = (deviceState, right, bottom, left, top) => {
  switch (deviceState) {
    case 'phone':
      return ''
    case 'small':
      return `
        width: 7rem;
        right: ${right}rem;
        bottom: ${bottom}rem;
        left: ${left}rem;
        top: ${top}rem;
      `
    case 'big':
      return `
        width: 6rem;
        max-width: 21rem;
        right: ${right}rem;
        bottom: ${bottom}rem;
        left: ${left}rem;
        top: ${top}rem;
      `
    default:
      return null
  }
}

const ImageRecherche = styled.img`
  transform: rotate(${(props) => props.rotate}deg);
  position: absolute;

  box-shadow: 76.317px 83.586px 31.799px 0px rgba(143, 70, 35, 0),
    49.061px 53.604px 29.073px 0px rgba(143, 70, 35, 0.02),
    27.256px 29.982px 24.531px 0px rgba(143, 70, 35, 0.07),
    12.72px 13.628px 18.171px 0px rgba(143, 70, 35, 0.12),
    2.726px 3.634px 9.994px 0px rgba(143, 70, 35, 0.14);

  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${RechercheContainer}:hover & {
    transform: rotate(${(props) => props.rotateHover});
  }

  ${(props) =>
    getImageResponsive(
      props.devicestate,
      props.right,
      props.bottom,
      props.left,
      props.top
    )}
`

/**
 * Composant Recherche qui affiche des informations sur la recherche UX
 * avec des images. Le layout s'adapte aux différentes tailles d'appareil.
 *
 * @component
 * @returns {JSX.Element} Composant Recherche
 */
function Recherche() {
  const deviceState = useResponsive()

  return (
    <RechercheContainer>
      <RechercheTextContainer>
        <Font.Title.TitreL style={{ margin: '0' }}>
          Recherche UX
        </Font.Title.TitreL>
        <Font.Text.TextM>
          Conduite d’enquêtes et d’analyses pour comprendre les besoins des
          utilisateurs.
        </Font.Text.TextM>
      </RechercheTextContainer>
      <RechercheImageContainer>
        <ImageRecherche
          devicestate={deviceState}
          alt="Page de recherche 2"
          src={recherchePage2}
          right={0.2}
          rotateHover="10deg"
        />
        <ImageRecherche
          devicestate={deviceState}
          alt="Page de recherche 1"
          src={recherchePage1}
          right={2.5}
          top={-0.18}
          rotate={-10}
          rotateHover="-20deg"
        />
      </RechercheImageContainer>
    </RechercheContainer>
  )
}

export default Recherche
