import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Header from './components/Header'
import Footer from './components/Footer'
import GlobalStyle from './utils/style/GlobalStyle'
import { RefProvider } from './utils/context'
import ProjetPage from './pages/ProjetPage'

// eslint-disable-next-line no-self-compare

ReactDOM.render(
  <React.StrictMode>
    <RefProvider>
      <Router>
        <GlobalStyle />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="projet/:projectId" element={<ProjetPage />} />
        </Routes>
        <Footer />
      </Router>
    </RefProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
