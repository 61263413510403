import styled from 'styled-components'
import { colorsToken } from '../../utils/style/colors'
import { Font } from '../../utils/style/Font'
import Projet from '../Projet'
import { useResponsive } from '../../utils/hooks'
import { projectList } from '../../contents/projectList'

/**
 * NoiseFilter component creates a noise effect in the background using an SVG filter.
 */
const NoiseFilter = () => (
  <svg width="0" height="0">
    <filter id="noiseFilter">
      <feTurbulence
        type="fractalNoise"
        baseFrequency="1.5"
        stitchTiles="stitch"
      />
      <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
      <feBlend in="SourceGraphic" in2="monoNoise" mode="screen" />
    </filter>
  </svg>
)

/**
 * ProjetsContainer component styles the main container for the projects section.
 */
const ProjetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8rem 0;
  color: ${colorsToken.text.Primary};
  background: ${colorsToken.surface.Invert};
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.1;
  }

  &:before {
    background: #161d19;
    filter: url(#noiseFilter);
  }
`

/**
 * ProjetContainer component styles the container for individual projects based on device state.
 */
const ProjetContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 9rem;
  max-width: ${({ deviceState }) =>
    deviceState === 'big' ? '78rem' : '41.3rem'};
`

/**
 * Titre component styles the main title for the projects section.
 */
const Titre = styled(Font.Title.TitreL)`
  font-size: 4.2rem;
  z-index: 1;
  color: ${colorsToken.text.Invert};
  margin-bottom: 10rem;
  text-align: center;
`

/**
 * Projets component renders the list of projects with responsive design.
 * @returns {JSX.Element} The rendered Projets component.
 */
function Projets() {
  const deviceState = useResponsive()

  return (
    <ProjetsContainer>
      <NoiseFilter />
      <Titre>Mes derniers projets</Titre>
      <ProjetContainer deviceState={deviceState}>
        {projectList.map(
          ({ id, name, date, image, tags, description }, index) => (
            <Projet
              key={id}
              titre={name}
              date={date}
              text={description}
              tags={tags}
              image={image}
              id={id}
              direction={index % 2 === 0 ? 'right' : 'left'}
            />
          )
        )}
      </ProjetContainer>
    </ProjetsContainer>
  )
}

export default Projets
