import { Font } from '../../../utils/style/Font'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import backgroundImagePattern from '../../../assets/Image/Bento/Atelier/PaternAtelier.png'

/**
 * Card component for displaying content with optional image and text.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered Card component.
 */
function Card({
  titre,
  text,
  color,
  backgroundImage,
  colorFont,
  image,
  topImage,
  bottomImage,
  rightImage,
  leftImage,
  widthImage,
  onlyImage,
  top,
}) {
  return (
    <CardContainer
      color={color}
      onlyImage={onlyImage}
      backgroundImage={backgroundImage}
      top={top}
    >
      {!onlyImage && (
        <TextContainer>
          <Title color={colorFont}>{titre}</Title>
          <StyledText color={colorFont}>{text}</StyledText>
        </TextContainer>
      )}

      <ImageContainer>
        <StyledImage
          src={image}
          top={topImage}
          bottom={bottomImage}
          right={rightImage}
          left={leftImage}
          width={widthImage}
        />
      </ImageContainer>
    </CardContainer>
  )
}

Card.propTypes = {
  titre: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  backgroundImage: PropTypes.bool,
  colorFont: PropTypes.string,
  image: PropTypes.string.isRequired,
  topImage: PropTypes.string,
  bottomImage: PropTypes.string,
  rightImage: PropTypes.string,
  leftImage: PropTypes.string,
  widthImage: PropTypes.string,
  onlyImage: PropTypes.bool,
  top: PropTypes.string,
}

Card.defaultProps = {
  titre: '',
  text: '',
  color: 'white',
  backgroundImage: false,
  colorFont: 'black',
  topImage: 'auto',
  bottomImage: 'auto',
  rightImage: 'auto',
  leftImage: 'auto',
  widthImage: '100%',
  onlyImage: false,
  top: '0',
}

export default Card

/**
 * Styled container for the Card component.
 * Applies different styles based on props passed.
 */
const CardContainer = styled.div`
  top: ${(props) => props.top};
  position: sticky;
  display: flex;
  flex-direction: column;
  border-radius: var(--Radius-Radius-full, 2rem);
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
  ${(props) => !props.onlyImage && `padding: 2.5rem;`}
  ${(props) =>
    props.backgroundImage
      ? `background-image: url(${backgroundImagePattern});`
      : `background-color: ${props.color};`}
  overflow: hidden;
  gap: 2.5rem;
`

/**
 * Styled container for text elements within the Card component.
 */
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

/**
 * Styled container for image within the Card component.
 */
const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

/**
 * Styled image element within the Card component.
 * Adjusts styles based on props passed.
 */
const StyledImage = styled.img`
  ${(props) =>
    props.width
      ? `width: ${props.width};`
      : `  
        width: 100%;
        height: 100%;`}

  position: absolute;
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
`

/**
 * Styled title element within the Card component.
 */
const Title = styled(Font.Title.TitreL)`
  margin: 0;
  color: ${(props) => props.color};
`

/**
 * Styled text element within the Card component.
 */
const StyledText = styled(Font.Text.TextM)`
  color: ${(props) => props.color};
`
