import React, { useRef, useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import animationDataDev from '../../assets/Animation/Animation-Dev.json'
import animationDataInge from '../../assets/Animation/Animation-Ingé.json'
import animationDataUX from '../../assets/Animation/Animation-UX.json'
import styled from 'styled-components'
import { Font } from '../../utils/style/Font'
import { colorsToken } from '../../utils/style/colors'
import { useResponsive } from '../../utils/hooks'

const LottieContainer = styled.div`
  width: 200%;
  min-width: 50rem;
  height: fit-content;
  position: absolute;
  top: -30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;

  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 1s ease; // Animation d'apparition en douceur
  pointer-events: ${({ isActive }) =>
    isActive ? 'auto' : 'none'}; // Empêche les interactions lorsque caché
`

const LandingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 1rem;
`

const TextAnimated = styled(Font.Text.TextL)`
  cursor: default;
  color: #98968a;
  animation-timing-function: ease-in;
  font-weight: 700;
  ${(props) =>
    props.selection === props.textselected &&
    `color: ${colorsToken.surface.SpecialBleu} !important;`}
`

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.44rem;
  position: relative;
  z-index: 2;
`

const selection = {
  Inge: 'Ingenieur',
  Dev: 'Dev',
  UX: 'UX',
}

const Titre = styled(Font.Title.TitreL)`
  margin: 0;
  text-align: center;
  ${(props) => {
    if (props.devicestate !== 'phone') {
      return `font-size: 6rem;`
    }
  }}
`

function Hero() {
  //Responsive
  const deviceState = useResponsive()

  //Animation
  const lottieRefDev = useRef(null)
  const lottieRefInge = useRef(null)
  const lottieRefUX = useRef(null)
  const lottieRefFull = useRef(null)

  const [textSelected, setTextSelected] = useState(selection.Inge)
  const [buffer, setBuffer] = useState(selection.Inge)
  const [boucleState, setBoucleState] = useState(true)

  const defaultOptionsDev = {
    loop: false,
    autoplay: false,
    animationData: animationDataDev,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const defaultOptionsInge = {
    loop: false,
    autoplay: false,
    animationData: animationDataInge,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const defaultOptionsUX = {
    loop: false,
    autoplay: false,
    animationData: animationDataUX,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value)
  }

  const getIndexOfLastHoverText = () => {
    if (buffer) {
      const key = getKeyByValue(selection, buffer)
      return Object.keys(selection).indexOf(key) // Obtenir l'index de la clé dans le tableau des clés
    }
    return 0
  }

  // Boucle pour changer `textSelected` toutes les 4 secondes
  useEffect(() => {
    if (!boucleState) return

    const selectionKeys = Object.keys(selection)
    let currentIndex = getIndexOfLastHoverText() + 1

    const interval = setInterval(() => {
      setTextSelected(null)

      setTimeout(() => {
        const newSelection = selection[selectionKeys[currentIndex]]
        setTextSelected(newSelection)
        setBuffer(newSelection)
        currentIndex = (currentIndex + 1) % selectionKeys.length
      }, 2000)
    }, 4000)

    return () => clearInterval(interval)
  }, [boucleState])

  useEffect(() => {
    // Ensure the animation is ready before trying to manipulate it
    if (lottieRefFull.current) {
      lottieRefFull.current.goToAndPlay(0, true)
    }
  }, [lottieRefFull])

  // Synchroniser les animations avec les changements d'état
  useEffect(() => {
    if (textSelected === selection.Inge && lottieRefInge.current) {
      lottieRefInge.current.playSegments([0, 60], true)
    } else if (textSelected === selection.Dev && lottieRefDev.current) {
      lottieRefDev.current.playSegments([0, 60], true)
    } else if (textSelected === selection.UX && lottieRefUX.current) {
      lottieRefUX.current.playSegments([0, 60], true)
    }
  }, [textSelected])

  // Gérer l'arrêt des animations lors du mouse leave
  useEffect(() => {
    if (textSelected === null) {
      if (lottieRefInge.current && buffer === selection.Inge) {
        lottieRefInge.current.playSegments([186, 202], true)
      } else if (lottieRefDev.current && buffer === selection.Dev) {
        lottieRefDev.current.playSegments([193, 210], true)
      } else if (lottieRefUX.current && buffer === selection.UX) {
        lottieRefUX.current.playSegments([186, 202], true)
      }
    }
  }, [textSelected, buffer])

  const handleMouseEnterInge = () => {
    setTextSelected(selection.Inge)
    setBuffer(selection.Inge)
    setBoucleState(false)
  }

  const handleMouseLeaveInge = () => {
    setTextSelected(null)
    setBoucleState(true)
  }

  const handleMouseEnterDev = () => {
    setTextSelected(selection.Dev)
    setBuffer(selection.Dev)
    setBoucleState(false)
  }

  const handleMouseLeaveDev = () => {
    setTextSelected(null)
    setBoucleState(true)
  }

  const handleMouseEnterUX = () => {
    setTextSelected(selection.UX)
    setBuffer(selection.UX)
    setBoucleState(false)
  }

  const handleMouseLeaveUX = () => {
    setTextSelected(null)
    setBoucleState(true)
  }

  return (
    <LandingContainer>
      <HeroTextContainer>
        <Titre devicestate={deviceState}>Josselin Le bihan</Titre>
        <Font.Text.TextL style={{ textAlign: 'center', maxWidth: '37.4rem' }}>
          <TextAnimated
            onMouseEnter={handleMouseEnterInge}
            onMouseLeave={handleMouseLeaveInge}
            selection={selection.Inge}
            textselected={textSelected}
          >
            Ingénieur,
          </TextAnimated>{' '}
          <TextAnimated
            onMouseEnter={handleMouseEnterDev}
            onMouseLeave={handleMouseLeaveDev}
            selection={selection.Dev}
            textselected={textSelected}
          >
            {' '}
            Développeur
          </TextAnimated>{' '}
          et{' '}
          <TextAnimated
            onMouseEnter={handleMouseEnterUX}
            onMouseLeave={handleMouseLeaveUX}
            selection={selection.UX}
            textselected={textSelected}
          >
            UX Designer
          </TextAnimated>{' '}
          je conçois des interfaces de bout en bout.
        </Font.Text.TextL>
        <LottieContainer isActive={textSelected === selection.Dev}>
          <Lottie
            options={defaultOptionsDev}
            ref={(instance) => {
              if (instance) {
                lottieRefDev.current = instance.anim
              }
            }}
          />
        </LottieContainer>
        <LottieContainer isActive={textSelected === selection.Inge}>
          <Lottie
            options={defaultOptionsInge}
            ref={(instance) => {
              if (instance) {
                lottieRefInge.current = instance.anim
              }
            }}
          />
        </LottieContainer>
        <LottieContainer isActive={textSelected === selection.UX}>
          <Lottie
            options={defaultOptionsUX}
            ref={(instance) => {
              if (instance) {
                lottieRefUX.current = instance.anim
              }
            }}
          />
        </LottieContainer>
      </HeroTextContainer>
    </LandingContainer>
  )
}

export default Hero
