import { colorsToken } from '../../utils/style/colors'
import styled from 'styled-components'
import { Font } from '../../utils/style/Font'
import iconArrow from '../../assets/Image/Icon/IconArrow.svg'
import { useNavigate } from 'react-router-dom'

/**
 * @typedef {Object} BoutonDecouvrirProps
 * @property {string} projectId - The ID of the project to navigate to.
 */

/**
 * BoutonDecouvrir component.
 * @param {BoutonDecouvrirProps} props - Props for the BoutonDecouvrir component.
 * @returns {JSX.Element} A button component that navigates to the project page when clicked.
 */
function BoutonDecouvrir({ projectId }) {
  const navigate = useNavigate()

  /**
   * Handle button click event to navigate to the project page.
   */
  const handleButtonClick = () => {
    navigate(`/projet/${projectId}`)
    window.scrollTo(0, 0)
  }

  return (
    /* From Uiverse.io by Creatlydev */
    <StyledButton onClick={handleButtonClick}>
      <Font.Text.TextBouton style={{ width: '100%', margin: '0' }}>
        Découvrir
      </Font.Text.TextBouton>
      <ButtonIconWrapper>
        <ButtonIcon src={iconArrow} alt="Arrow Icon" />
        <ButtonIconCopy src={iconArrow} alt="Arrow Icon Copy" />
      </ButtonIconWrapper>
    </StyledButton>
  )
}

export default BoutonDecouvrir

const StyledButton = styled.a`
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: ${colorsToken.surface.Primary};
  color: ${colorsToken.text.Primary};
  border-radius: 10rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  width: 7rem;
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(20, 31, 26, 0.01),
    19px 47px 31px 0px rgba(20, 31, 26, 0.05),
    8px 21px 23px 0px rgba(20, 31, 26, 0.09),
    2px 5px 12px 0px rgba(20, 31, 26, 0.1);
`
/**
 * Wrapper for the button icon in BoutonDecouvrir.
 * It ensures the icon is centered and styled correctly.
 */
const ButtonIconWrapper = styled.span`
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;

  ${StyledButton}:hover & {
    /* Add hover styles here if needed */
  }
`
/**
 * Styled image component for the button icon.
 * Handles the transition and transform on hover.
 */
const ButtonIcon = styled.img`
  ${StyledButton}:hover & {
    transition: transform 0.3s ease-in-out;
    transform: translate(150%);
  }
`
/**
 * Styled image component for the copy of the button icon.
 * Handles position and transition on hover.
 */
const ButtonIconCopy = styled.img`
  position: absolute;
  transform: translate(-150%);
  ${StyledButton}:hover & {
    transition: transform 0.3s ease-in-out 0.1s;
    transform: translate(0);
    color: ${colorsToken.text.Brand};
  }
`
