const colorsPrimitive = {
  noir: '#1B181C',
  gris: '#E6E6E6',
  vert: '#2B4B3C',
  vertClaire: '#89965D',
  beige: '#FFFFE6',
  rose: '#FFB4C9',
  jaune: '#EBFF47',
  orange: '#FF7F42',
  blanc: '#FFFFFF',
  bleu: '#5E96FE',
}

const bordure = {
  Primary: colorsPrimitive.noir,
  Secondary: colorsPrimitive.gris,
}

const surface = {
  Primary: colorsPrimitive.beige,
  Black: colorsPrimitive.noir,
  Invert: colorsPrimitive.vert,
  Accent: colorsPrimitive.jaune,
  SpecialRose: colorsPrimitive.rose,
  SpecialOrange: colorsPrimitive.orange,
  SpecialBlanc: colorsPrimitive.blanc,
  SpecialVertClair: colorsPrimitive.vertClaire,
  SpecialBleu: colorsPrimitive.bleu,
  Gris: colorsPrimitive.gris,
}

const text = {
  Primary: colorsPrimitive.noir,
  Invert: colorsPrimitive.beige,
  Brand: colorsPrimitive.vert,
}

export const colorsToken = {
  bordure,
  text,
  surface,
}
