import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import Hero from '../../components/Hero'
import Bento from '../../components/Bento'
import Cards from '../../components/Cards'
import Projets from '../../components/Projets'
import { useResponsive, useDeviceType } from '../../utils/hooks'
import { RefContext } from '../../utils/context'
import { Font } from '../../utils/style/Font'
import arrow from '../../assets/Image/Landing/Arrow.svg'

/** Main container for the Home page */
const HomeSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`

/** Explore button container */
const ExploreButton = styled.button`
  position: absolute;
  top: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
`

/** Presentation container with background styling */
const PresentationSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ffffe6;
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
  }

  &:before {
    background: #f0dba5;
    filter: url(#noiseFilter);
  }
`

/** Section with scroll-snap behavior */
const ScrollSection = styled.section`
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  scroll-snap-align: start;
`

/** Responsive styles for the ScaledContentWrapper based on device type */
const getImageResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return `
        // Add specific styles for phone here
      `
    case 'small':
      return `
        // Add specific styles for small devices here
      `
    case 'big':
      return `
        // Add specific styles for big devices here
      `
    default:
      return null
  }
}

/** Wrapper for scaling content based on device state */
const ScaledContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${(props) => getImageResponsive(props.devicestate)}
`

/** Container for scaled content */
const ScaledContent = styled.div`
  transform: scale(${(props) => props.scale});
  transform-origin: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.defaultWidth}px;
  height: ${(props) => props.defaultHeight}px;
  margin: 0 2rem; /* 2rem margin on sides */
`

/**
 * Home component for the landing page.
 *
 * This component handles responsive design, scaling, and scroll behavior.
 *
 * @returns {JSX.Element} The rendered Home component.
 */
const Home = () => {
  const { projetsRef, accueilsRef, bentoRef, setPage } = useContext(RefContext)

  useEffect(() => {
    setPage('Home')
  }, [setPage])

  // Responsive state
  const deviceState = useResponsive()
  const deviceType = useDeviceType()

  /**
   * Get default dimensions based on the device state.
   *
   * @param {string} deviceState - The current device state (e.g., 'phone', 'small', 'big').
   * @returns {{defaultWidth: number, defaultHeight: number}} The default width and height.
   */
  const getDefaultDimensions = (deviceState) => {
    switch (deviceState) {
      case 'phone':
        return { defaultWidth: 0, defaultHeight: 0 }
      case 'small':
        return { defaultWidth: 50 * 16, defaultHeight: 106 * 16 }
      case 'big':
      default:
        return { defaultWidth: 76 * 16, defaultHeight: 64 * 16 }
    }
  }

  const { defaultWidth, defaultHeight } = getDefaultDimensions(deviceState)
  const [scale, setScale] = useState(1)

  useEffect(() => {
    /**
     * Handle the window resize event to update the scale of the content.
     */
    const handleResize = () => {
      const containerWidth = window.innerWidth
      const containerHeight = window.innerHeight
      let newScale = 1

      if (deviceState === 'small') {
        if (containerWidth <= 990) {
          newScale = (containerWidth - 4 * 16) / (50 * 16)
        }
      } else {
        const scaleWidth = (containerWidth - 4 * 16) / defaultWidth
        const scaleHeight = containerHeight / defaultHeight
        newScale = Math.min(scaleWidth, scaleHeight)
      }

      setScale(newScale > 1 ? 1 : newScale)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [defaultWidth, defaultHeight, deviceState])

  /**
   * Scroll to the Bento section smoothly.
   */
  const handleScrollToBento = () => {
    if (bentoRef.current) {
      bentoRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' })
    } else {
      console.error('Bento ref is not available.')
    }
  }

  return (
    <HomeSection devicestate={deviceState}>
      <PresentationSection>
        <ExploreButton
          onClick={handleScrollToBento}
          aria-label="Explorer mon univers"
        >
          <Font.Text.TextM>Explorer mon univers!</Font.Text.TextM>
          <img
            src={arrow}
            alt="Flèche indiquant de faire défiler"
            style={{ width: '16px', height: '196px' }}
          />
        </ExploreButton>
        <ScrollSection
          ref={accueilsRef}
          devicetype={deviceType}
          id="Accueil"
          style={{ marginBottom: '16rem' }}
        >
          <Hero />
        </ScrollSection>

        {deviceState === 'phone' ? (
          <Cards />
        ) : (
          <ScrollSection style={{ padding: '0rem 0 10rem 0' }} ref={bentoRef}>
            <ScaledContentWrapper devicestate={deviceState}>
              <ScaledContent
                scale={scale}
                defaultWidth={defaultWidth}
                defaultHeight={defaultHeight}
              >
                <Bento />
              </ScaledContent>
            </ScaledContentWrapper>
          </ScrollSection>
        )}
      </PresentationSection>
      <ScrollSection ref={projetsRef} id="Projet">
        <Projets />
      </ScrollSection>
    </HomeSection>
  )
}

export default Home
