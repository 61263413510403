import styled from 'styled-components'
import ButtonContact from '../ButtonContact'
import { colorsToken } from '../../utils/style/colors'
import { useContext, useEffect, useState, useCallback } from 'react'
import { RefContext } from '../../utils/context'
import { useResponsive } from '../../utils/hooks'
import { useNavigate } from 'react-router-dom'
import cv from '../../contents/CV/CV-Josselin-Le-Bihan.pdf'

/**
 * Styled component for the header container.
 * Adjusts its position based on scroll state.
 */
const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 4.5rem;
  align-items: center;
  justify-content: center;
  gap: 32px;
  flex-shrink: 0;
  background: var(--Surface-Surface-primary, #ffffe6);
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0),
    19px 47px 31px 0px rgba(122, 122, 102, 0.03),
    8px 21px 23px 0px rgba(122, 122, 102, 0.07),
    2px 5px 12px 0px rgba(122, 122, 102, 0.08);
  z-index: 99;
  transition: transform 300ms ease-in-out;

  transform: ${({ scrollState }) =>
    scrollState === 'down' ? 'translate3d(0,-100%,0)' : 'translate3d(0, 0, 0)'};
`

/**
 * Styled component for links in the header.
 * Shared styles for both anchor and button links.
 */
const StyledLink = styled.button`
  text-decoration: none;
  font-feature-settings: 'calt' off;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  transition: color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

  &:focus,
  &:hover {
    color: ${colorsToken.text.Brand};
  }

  &:after {
    content: '';
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: ${colorsToken.text.Brand};
    transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
      left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &:focus:after,
  &:hover:after {
    width: 100%;
    left: 0%;
  }
`

/**
 * Styled component specifically for anchor links (e.g., downloading CV).
 */
const StyledAnchor = styled(StyledLink).attrs({ as: 'a' })`
  /* Inherit styles from StyledLink and add specific anchor behaviors */
`

const ButtonContainer = styled.div`
  margin-right: 2rem;
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-left: 2rem;
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 78rem;
  justify-content: space-between;
`

/**
 * Header component for the webpage.
 * Includes navigation and a contact button.
 * @returns {JSX.Element} The rendered Header component.
 */
function Header() {
  const [scrollState, setScrollState] = useState('up')
  const [lastScroll, setLastScroll] = useState(0)
  const { projetsRef, accueilsRef } = useContext(RefContext)
  const deviceState = useResponsive()
  const buttonTheme = deviceState === 'phone' ? 'small' : 'big'
  const { page } = useContext(RefContext)
  const navigate = useNavigate()

  // Handles scrolling to the "Projets" section
  const handleScrollToProjets = useCallback(() => {
    if (projetsRef.current) {
      projetsRef.current.scrollIntoView({ behavior: 'smooth' })
    } else {
      console.error('Projets ref is not available.')
    }
  }, [projetsRef])

  // Handles scrolling to the "Accueil" section
  const handleScrollToAccueil = useCallback(() => {
    if (accueilsRef.current) {
      accueilsRef.current.scrollIntoView({ behavior: 'smooth' })
    } else {
      console.error('Accueil ref is not available.')
    }
  }, [accueilsRef])

  // Navigates to "Projets" page and scrolls to section after loading
  const handleNavigateToProjets = () => {
    navigate('/') // Navigates to the home page
    setTimeout(() => {
      const section = document.getElementById('Projet')
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 100) // Timeout to ensure the page is fully loaded
  }

  // Navigates to "Accueil" page
  const handleNavigateToAccueil = () => {
    navigate('/#Accueil')
  }

  // Handles the scroll event to determine the header's visibility
  const handleScrollHome = () => {
    const currentScroll = window.pageYOffset

    if (currentScroll > lastScroll) {
      setScrollState('down')
    } else if (currentScroll < lastScroll || currentScroll === 0) {
      setScrollState('up')
    }

    setLastScroll(currentScroll)
  }

  useEffect(() => {
    if (deviceState !== 'phone') {
      window.addEventListener('scroll', handleScrollHome)
      return () => {
        window.removeEventListener('scroll', handleScrollHome)
      }
    }
  }, [lastScroll, deviceState]) // Updated dependencies for useEffect

  return (
    <HeaderContainer scrollState={scrollState}>
      <ContentContainer>
        <LinkContainer>
          <StyledLink
            as="button"
            onClick={
              page === 'Home' ? handleScrollToAccueil : handleNavigateToAccueil
            }
          >
            Accueil
          </StyledLink>
          <StyledLink
            as="button"
            onClick={
              page === 'Home' ? handleScrollToProjets : handleNavigateToProjets
            }
          >
            Projet UX
          </StyledLink>
          <StyledAnchor href={cv} target="_blank" rel="noopener noreferrer">
            CV
          </StyledAnchor>
        </LinkContainer>

        <ButtonContainer>
          <ButtonContact text="me contacter" theme={buttonTheme} />
        </ButtonContainer>
      </ContentContainer>
    </HeaderContainer>
  )
}

export default Header
