import styled from 'styled-components'
import { Font } from '../../../utils/style/Font'
import atelierToolBar from '../../../assets/Image/Bento/Atelier/ToolbarAtelier.svg'
import patternAtelier from '../../../assets/Image/Bento/Atelier/PaternAtelier.png'
import { colorsToken } from '../../../utils/style/colors'
import fixElement from '../../../assets/Image/Bento/Atelier/FixElement.svg'
import georgeUser from '../../../assets/Image/Bento/Atelier/GeorgeUser.svg'
import hearthElement from '../../../assets/Image/Bento/Atelier/HearthElement.svg'
import josefUser from '../../../assets/Image/Bento/Atelier/JosefUser.svg'
import kevinUser from '../../../assets/Image/Bento/Atelier/KevinUser.svg'
import stapElement from '../../../assets/Image/Bento/Atelier/StapElement.svg'
import { useResponsive } from '../../../utils/hooks'

/**
 * Returns responsive styling for AtelierContainer based on the device state.
 * @param {string} deviceState - The current device state (phone, small, big).
 * @returns {string|null} The CSS styles based on the device state.
 */
const getAtelierContainerResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ''
    case 'small':
      return `
        flex: 1;
      `
    case 'big':
      return `
        flex: 2;
      `
    default:
      return null
  }
}

const AtelierContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2rem;
  gap: 2.5rem;
  border: 2px solid ${colorsToken.bordure.Secondary};
  background-image: url(${patternAtelier});
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
  ${(props) => getAtelierContainerResponsive(props.devicestate)}
`

const AtelierTextContainer = styled.div`
  padding: 2.5rem 2.5rem 0 2.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
`
const AtelierImageContainer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
`
const ImageAtelier = styled.img`
  height: fit-content;
  position: absolute;
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  opacity: ${(props) => props.opacity};
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${AtelierContainer}:hover & {
    ${(props) =>
      props.move
        ? `
          top: ${props.topHover || props.top};
          bottom: ${props.bottomHover || props.bottom};
          left: ${props.leftHover || props.left};
          right: ${props.rightHover || props.right};
        `
        : `opacity: ${props.opacityHover};`}
  }
`
/**
 * Atelier component that displays collaborative workshop information and images.
 * The layout adapts to different device sizes.
 * @component
 */
function Atelier() {
  const deviceState = useResponsive()
  return (
    <AtelierContainer devicestate={deviceState}>
      <AtelierTextContainer>
        <Font.Title.TitreL style={{ margin: '0' }}>Atelier</Font.Title.TitreL>
        <Font.Text.TextM>
          Animation d’ateliers collaboratifs pour développer des solutions UX
          personnalisées.
        </Font.Text.TextM>
      </AtelierTextContainer>
      <AtelierImageContainer>
        <ImageAtelier
          src={georgeUser}
          bottom="22rem"
          right="2.25rem"
          move
          rightHover="0.75rem"
          bottomHover="25rem"
        />
        <ImageAtelier
          src={hearthElement}
          bottom="24rem"
          right="5.9rem"
          move
          rightHover="4.44rem"
          bottomHover="27rem"
        />
        <ImageAtelier
          src={josefUser}
          bottom="23.3rem"
          right="10.1rem"
          opacity="1"
          opacityHover="0"
        />
        <ImageAtelier
          src={kevinUser}
          bottom="9.94rem"
          left="7.7rem"
          move
          leftHover="2.62rem"
          bottomHover="10.5rem"
        />
        <ImageAtelier
          src={stapElement}
          bottom="12.22rem"
          left="0.81rem"
          opacity="0"
          opacityHover="1"
        />
        <ImageAtelier src={fixElement} bottom="10.72rem" />
        <ImageAtelier src={atelierToolBar} bottom="-1rem" left="-1.5rem" />
      </AtelierImageContainer>
    </AtelierContainer>
  )
}

export default Atelier
