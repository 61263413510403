import styled from 'styled-components'
import React from 'react'
import Atelier from './Atelier'
import Conception from './Conception'
import Contact from './Contact'
import Developpement from './Developpement'
import Recherche from './Recherche'
import TestsUtilisateur from './TestUtilisateurs'
import Tools from './Tools'
import { useResponsive } from '../../utils/hooks'

/**
 * Renvoie les styles réactifs pour le conteneur Bento en fonction de l'état de l'appareil.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @returns {string} Les styles CSS pour le design réactif.
 */
const getBentoSectionResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        flex-direction: column;
        height: 100rem;
      `
    case 'big':
      return `
        height: 64.6rem;
      `
    default:
      return ``
  }
}

const BentoSection = styled.div`
  width: 100%;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  position: static;

  ${(props) => getBentoSectionResponsive(props.devicestate)}
`

/**
 * Renvoie les styles réactifs pour la colonne droite en fonction de l'état de l'appareil.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @returns {string} Les styles CSS pour le design réactif.
 */
const getColRightResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        display: grid;
        grid-template-columns : 1fr 1fr;
        flex-direction: row;
        height: 59rem;
      `
    case 'big':
      return `
        display: flex;
        flex-direction: column;
        width: 25rem;
        height: 100%;
      `
    default:
      return ``
  }
}

const ColRightSection = styled.div`
  gap: 2.5rem;
  ${(props) => getColRightResponsive(props.devicestate)}
`

const ColLeftSection = styled.div`
  width: 50rem;
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: 2.5rem;
`

const ColLeftRowTopSection = styled.div`
  display: flex;
  flex: 2;
  gap: 2.5rem;
`

const LeftRowTopLeftSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2.5rem;
  align-items: stretch; // Assure que les enfants prennent la même hauteur
  height: 100%; // Assure que le parent a une hauteur définie
`

const LeftRowTopRightSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2.5rem;
`

const ColLeftRowBottomSection = styled.div`
  display: flex;
  flex: 1;
  gap: 2.5rem;
`

/**
 * Composant Bento qui gère la disposition des sections de contenu.
 * Le layout s'adapte aux différentes tailles d'appareil.
 *
 * @component
 * @returns {JSX.Element} Composant Bento
 */
function Bento() {
  const deviceState = useResponsive()

  return (
    <BentoSection devicestate={deviceState}>
      <ColLeftSection>
        <ColLeftRowTopSection>
          <LeftRowTopLeftSection>
            <Tools />
            <Recherche />
          </LeftRowTopLeftSection>
          <LeftRowTopRightSection>
            <Developpement />
            <Contact />
          </LeftRowTopRightSection>
        </ColLeftRowTopSection>
        <ColLeftRowBottomSection>
          <Conception />
        </ColLeftRowBottomSection>
      </ColLeftSection>
      <ColRightSection devicestate={deviceState}>
        <TestsUtilisateur />
        <Atelier />
      </ColRightSection>
    </BentoSection>
  )
}

export default Bento
