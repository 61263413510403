import { createGlobalStyle } from 'styled-components'
import { colorsToken } from './colors'

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: "Inter";
      color: ${colorsToken.text.Primary}
    }

    body, html {
      margin: 0;
    }
`

function GlobalStyle() {
  return (
    <head>
      <StyledGlobalStyle />
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        href="https://fonts.cdnfonts.com/css/lokanova"
        rel="stylesheet"
      ></link>

      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
        rel="stylesheet"
      ></link>
    </head>
  )
}

export default GlobalStyle
