import styled from 'styled-components'
import Card from './Card'
import { colorsToken } from '../../utils/style/colors'
import conceptionImage from '../../assets/Image/Card/conceptionImage.svg'
import rechercheImage from '../../assets/Image/Card/RechercheImage.svg'
import toolsImage from '../../assets/Image/Card/ToolsImage.svg'
import devImage from '../../assets/Image/Card/Developpementmage.svg'
import testsImage from '../../assets/Image/Card/TestsImage.svg'
import contactImage from '../../assets/Image/Card/ContactImage.svg'
import atelierImage from '../../assets/Image/Card/AtelierImage.svg'

/**
 * Styled component for the container of all cards.
 * Applies grid layout and positions cards based on the screen size.
 */
const CardsContainer = styled.div`
  position: sticky;
  top: 20rem;
  display: grid;
  margin-top: 10rem;
  margin-bottom: 5rem;
  grid-template-rows: 31.5rem 31.5rem 31.5rem 31.5rem 31.5rem 31.5rem 31.5rem;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
`
/**
 * Cards component renders a list of Card components with various props.
 * @returns {JSX.Element} The rendered Cards component.
 */
function Cards() {
  return (
    <CardsContainer>
      <Card
        titre="Conception"
        text="Réalisation de wireframes, maquettes et prototypes fonctionnels avec Figma."
        color={colorsToken.surface.Invert}
        colorFont={colorsToken.text.Invert}
        image={conceptionImage}
        top={'7rem'}
      />
      <Card
        titre="Recherche"
        text="Conduite d’enquêtes et d’analyses pour comprendre les besoins des utilisateurs."
        color={colorsToken.surface.SpecialOrange}
        image={rechercheImage}
        top={'9rem'}
      />
      <Card
        color={colorsToken.surface.SpecialBleu}
        image={toolsImage}
        onlyImage="true"
        top={'11rem'}
        topImage={'4rem'}
      />
      <Card
        titre="Développement"
        text="Création d’interfaces interactives en React, HTML, CSS et C#."
        color={colorsToken.surface.Black}
        colorFont={colorsToken.text.Invert}
        image={devImage}
        top={'13rem'}
      />
      <Card
        titre="Tests utilisateurs"
        text="Intégration de tests utilisateurs pour valider et améliorer les interfaces."
        color={colorsToken.surface.Invert}
        colorFont={colorsToken.text.Invert}
        image={testsImage}
        widthImage="18rem"
        rightImage="-4rem"
        top={'15rem'}
      />
      <Card
        titre="Atelier"
        text="Animation d’ateliers collaboratifs pour développer des solutions UX personnalisées."
        bacgroundimage={'../../assets/Image/Bento/Atelier/PaternAtelier.png'}
        image={atelierImage}
        widthImage="90%"
        rightImage="10%"
        top={'17rem'}
      />
      <Card
        titre="Me contacter"
        text="Prêt à concrétiser vos idées ? Contactez-moi et créons ensemble !"
        color={colorsToken.surface.SpecialRose}
        image={contactImage}
        top={'19rem'}
      />
    </CardsContainer>
  )
}

export default Cards
