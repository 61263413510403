import React from 'react'
import styled from 'styled-components'
import { colorsToken } from '../../../utils/style/colors'
import iconPhotoshop from '../../../assets/Image/Bento/Tools/iconePhotoshop.svg'
import iconFigma from '../../../assets/Image/Bento/Tools/iconeFigma.svg'
import iconGit from '../../../assets/Image/Bento/Tools/iconeGit.svg'
import iconHtml from '../../../assets/Image/Bento/Tools/iconeHtml.svg'
import iconIllustrator from '../../../assets/Image/Bento/Tools/iconeIllustrator.svg'
import iconJava from '../../../assets/Image/Bento/Tools/iconeJava.svg'
import iconNotion from '../../../assets/Image/Bento/Tools/iconeNotion.svg'
import iconVisualstudio from '../../../assets/Image/Bento/Tools/iconeVisualstudio.svg'
import iconReact from '../../../assets/Image/Bento/Tools/iconeReact.svg'

const ToolsSection = styled.div`
  flex: 1;
  background: ${colorsToken.surface.SpecialBleu};
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
`

const ColonneSection = styled.div`
  transform: rotate(-10deg);
  width: 100%;
  height: 100%;
`

/**
 * Colonne avec positionnement dynamique et transition lors du survol.
 *
 * @param {string} top - Position du haut.
 * @param {string} bottom - Position du bas.
 * @param {string} left - Position de gauche.
 * @param {string} right - Position de droite.
 * @param {string} topHover - Position du haut lors du survol.
 * @param {string} bottomHover - Position du bas lors du survol.
 * @param {string} leftHover - Position de gauche lors du survol.
 * @param {string} rightHover - Position de droite lors du survol.
 */
const Colonne = styled.div`
  width: 6rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: absolute;
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};

  ${ToolsSection}:hover & {
    top: ${(props) => props.topHover || props.top};
    bottom: ${(props) => props.bottomHover || props.bottom};
    left: ${(props) => props.leftHover || props.left};
    right: ${(props) => props.rightHover || props.right};
  }
`

const IconSection = styled.div`
  width: 4.13rem;
  height: 4.13rem;
  border-radius: 10rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border: 2px solid ${colorsToken.bordure.Primary};
  background: ${colorsToken.surface.Primary};
`

const Icon = styled.img`
  width: 100%;
  height: 100%;
`

/**
 * Composant Tools qui affiche une grille d'icônes d'outils avec des animations
 * de position lors du survol.
 *
 * @component
 * @returns {JSX.Element} Composant Tools
 */
function Tools() {
  return (
    <ToolsSection>
      <ColonneSection>
        <Colonne left="1rem" bottom="1rem" bottomHover="-1rem">
          <IconSection>
            <Icon src={iconPhotoshop} />
          </IconSection>
          <IconSection>
            <Icon src={iconFigma} />
          </IconSection>
          <IconSection>
            <Icon src={iconVisualstudio} />
          </IconSection>
        </Colonne>
        <Colonne right="1rem" bottom="1rem" bottomHover="-1rem">
          <IconSection>
            <Icon src={iconReact} />
          </IconSection>
          <IconSection>
            <Icon src={iconNotion} />
          </IconSection>
          <IconSection>
            <Icon src={iconIllustrator} />
          </IconSection>
        </Colonne>
        <Colonne left="8.7rem" top="1rem" topHover="-1rem">
          <IconSection>
            <Icon src={iconHtml} />
          </IconSection>
          <IconSection>
            <Icon src={iconGit} />
          </IconSection>
          <IconSection>
            <Icon src={iconJava} />
          </IconSection>
        </Colonne>
      </ColonneSection>
    </ToolsSection>
  )
}

export default Tools
