import styled from 'styled-components'
import { colorsToken } from '../../../utils/style/colors'
import { Font } from '../../../utils/style/Font'
import TestImage from '../../../assets/Image/Bento/TestUtilisateur/TestsUtilisateurImage.svg'
import TestDescriptionImage from '../../../assets/Image/Bento/TestUtilisateur/TestsUtilisateurDescriptionImage.svg'
import { useResponsive } from '../../../utils/hooks'

const TestsUtilisateurSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colorsToken.surface.Invert};
  flex: 1;
  border-radius: 2rem 2.5rem 2.5rem 2.5rem;
  padding: 2.5rem 0 0 2.5rem;
  gap: 1rem;
  position: relative;
`

/**
 * Renvoie les styles réactifs pour le conteneur de contenu en fonction de l'état de l'appareil.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @returns {string|null} Les styles CSS pour le design réactif.
 */
const getContentSectionResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        flex-direction: column;
      `
    case 'big':
      return `
        flex-direction: row;
      `
    default:
      return null
  }
}

const ContentSection = styled.div`
  display: flex;
  flex: 1;
  gap: 2.5rem;

  ${(props) => getContentSectionResponsive(props.devicestate)}
`

/**
 * Renvoie les styles réactifs pour le conteneur d'image en fonction de l'état de l'appareil.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @returns {string|null} Les styles CSS pour le design réactif.
 */
const getImageSectionResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        border-radius:  0 0 2.5rem 0;
      `
    case 'big':
      return ``
    default:
      return null
  }
}

const ImageSection = styled.div`
  position: relative;
  flex-shrink: 0;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${(props) => getImageSectionResponsive(props.devicestate)}
`

/**
 * Renvoie les styles réactifs pour l'image en fonction de l'état de l'appareil.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @returns {string|null} Les styles CSS pour le design réactif.
 */
const getImageResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        width: 17rem;
        right: -2rem;
      `
    case 'big':
      return `
        bottom: 0;
      `
    default:
      return null
  }
}

const Image = styled.img`
  height: fit-content;
  position: absolute;
  transform: rotate(${(props) => props.rotate});
  filter: drop-shadow(3px 4px 11px rgba(20, 31, 26, 0.14))
    drop-shadow(14px 15px 20px rgba(20, 31, 26, 0.12))
    drop-shadow(30px 33px 27px rgba(20, 31, 26, 0.07))
    drop-shadow(54px 59px 32px rgba(20, 31, 26, 0.02))
    drop-shadow(84px 92px 35px rgba(143, 70, 35, 0));
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${(props) => getImageResponsive(props.devicestate)}
`

/**
 * Renvoie les styles réactifs pour l'animation en fonction de l'état de l'appareil.
 *
 * @param {string} deviceState - L'état actuel de l'appareil (phone, small, big).
 * @returns {string|null} Les styles CSS pour le design réactif.
 */
const getAnimationResponsive = (deviceState) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        width: 17rem;
        bottom: 2.5rem;
        right: 2rem;

        ${TestsUtilisateurSection}:hover & {
          opacity: 1;
          right: 8rem;
        }
      `
    case 'big':
      return `
        bottom: 1.69rem;
        right: 4rem;

        ${TestsUtilisateurSection}:hover & {
          opacity: 1;
          right: 7rem;
        }
      `
    default:
      return null
  }
}

const Animation = styled.img`
  height: fit-content;
  position: absolute;
  transform: rotate(${(props) => props.rotate});
  filter: drop-shadow(3px 4px 11px rgba(20, 31, 26, 0.14))
    drop-shadow(14px 15px 20px rgba(20, 31, 26, 0.12))
    drop-shadow(30px 33px 27px rgba(20, 31, 26, 0.07))
    drop-shadow(54px 59px 32px rgba(20, 31, 26, 0.02))
    drop-shadow(84px 92px 35px rgba(143, 70, 35, 0));
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  opacity: 0;

  ${(props) => getAnimationResponsive(props.devicestate)}
`

/**
 * Composant TestsUtilisateur qui affiche des informations sur les tests utilisateurs
 * avec des images et une animation. Le layout s'adapte aux différentes tailles d'appareil.
 *
 * @component
 * @returns {JSX.Element} Composant TestsUtilisateur
 */
function TestsUtilisateur() {
  const deviceState = useResponsive()

  return (
    <TestsUtilisateurSection>
      <Font.Title.TitreL
        style={{
          margin: '0',
          color: colorsToken.text.Invert,
        }}
      >
        Tests utilisateurs
      </Font.Title.TitreL>
      <ContentSection devicestate={deviceState}>
        <Font.Text.TextM
          style={{
            margin: '0',
            color: colorsToken.text.Invert,
            width: '9rem',
          }}
        >
          Intégration de tests utilisateurs pour valider et améliorer les
          interfaces.
        </Font.Text.TextM>
        <div style={{ flex: '1', display: 'flex' }}>
          <ImageSection devicestate={deviceState}>
            <Image src={TestImage} devicestate={deviceState} bottom="0" />
          </ImageSection>
        </div>
      </ContentSection>

      <Animation src={TestDescriptionImage} devicestate={deviceState} />
    </TestsUtilisateurSection>
  )
}

export default TestsUtilisateur
