import { css } from 'styled-components'
import styled from 'styled-components'
import { colorsToken } from './colors'
import { Font } from './Font'
import tagIcon from '../../assets/Image/Icon/IconTag.svg'

export const shadow = (color) => {
  const shadow = css`
    box-shadow: 53px 131px 40px 0px ${color}, 34px 84px 36px 0px ${color},
      19px 47px 31px 0px ${color}, 8px 21px 23px 0px ${color},
      2px 5px 12px 0px ${color};
  `
  return shadow
}

/**TAGS
 */

const TagContainer = styled.div`
  display: flex;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  background: ${colorsToken.surface.SpecialOrange};
  box-shadow: 84px 92px 35px 0px rgba(143, 70, 35, 0),
    54px 59px 32px 0px rgba(20, 31, 26, 0.02),
    30px 33px 27px 0px rgba(20, 31, 26, 0.07),
    14px 15px 20px 0px rgba(20, 31, 26, 0.12),
    3px 4px 11px 0px rgba(20, 31, 26, 0.14);
`
const TagIcon = styled.img`
  width: 0.69338rem;
  height: 0.69338rem;
`

export const Tags = ({ text }) => {
  return (
    <TagContainer>
      <TagIcon src={tagIcon} alt="" />
      <Font.Text.TextS style={{ margin: '0' }}>{text}</Font.Text.TextS>
    </TagContainer>
  )
}

//NOISE
