import styled from 'styled-components'
import { colorsToken } from '../../utils/style/colors'
import PropTypes from 'prop-types'
import iconContact from '../../assets/Image/Icon/IconContact.svg'

/**
 * @typedef {Object} ButtonContactProps
 * @property {string} text - Text to display in the button.
 * @property {string} theme - Theme of the button, either 'big' or 'small'.
 * @property {Function} [onClick] - Function to call when the button is clicked.
 */

/**
 * ButtonContact component for displaying a styled button with optional text and icon.
 * @param {ButtonContactProps} props - The props for the ButtonContact component.
 * @returns {JSX.Element} The ButtonContact component.
 */
function ButtonContact({ text, onClick, theme }) {
  return (
    <StyledButton
      onClick={onClick}
      theme={theme}
      href="mailto:josselinlebihan9@gmail.com"
    >
      {theme !== 'small' && <StyledText>{text}</StyledText>}
      <StyledIcon src={iconContact} alt="Contact Icon" />
    </StyledButton>
  )
}

ButtonContact.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['big', 'small']).isRequired,
  onClick: PropTypes.func,
}

export default ButtonContact

/**
 * Styled icon component for ButtonContact.
 * Includes animation on hover.
 */
const StyledIcon = styled.img`
  z-index: 10;

  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

/**
 * Styled button component for ButtonContact.
 * Applies different styles based on the theme prop.
 */
const StyledButton = styled.a`
  ${(props) => {
    if (props.theme === 'big') {
      return `
        background: ${colorsToken.surface.Primary};
        border-radius: 2rem;   
        text-decoration: none; /* Retire l'effet souligné */
      `
    } else {
      return `
        background: ${colorsToken.surface.Primary};
        width: 2.5rem;
        border-radius: 0.5rem;
        text-decoration: none; /* Retire l'effet souligné */
      `
    }
  }}

  cursor: pointer;
  border: 2px solid ${colorsToken.bordure.borderPrimary};
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:hover ${StyledIcon} {
    animation: shake 0.3s;
  }
`

/**
 * Styled span component for button text.
 */
const StyledText = styled.span`
  color: ${colorsToken.text.textPrimary};
  font-feature-settings: 'calt' off;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.16px;
  z-index: 10;
`
