import { useState, useEffect } from 'react'

const responsiveStatus = {
  big: 'big',
  small: 'small',
  phone: 'phone',
}

// Fonction pour vérifier si l'appareil est un mobile
function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return (
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  )
}

function getDeviceState(windowWidth) {
  if (isMobileDevice() || windowWidth < 700) {
    return responsiveStatus.phone
  }
  if (windowWidth <= 990) {
    return responsiveStatus.small
  }
  return responsiveStatus.big
}

export function useResponsive() {
  const [deviceState, setDeviceState] = useState(
    getDeviceState(window.innerWidth)
  )

  useEffect(() => {
    const handleResize = () => {
      setDeviceState(getDeviceState(window.innerWidth))
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Check the device type on initial render

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return deviceState
}

export function useDeviceType() {
  if (isMobileDevice) {
    return 'phone'
  } else {
    return 'computer'
  }
}

export function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return height
}

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}
