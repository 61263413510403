import styled from 'styled-components'
import { colorsToken } from '../../../utils/style/colors'
import { Font } from '../../../utils/style/Font'
import conceptionPage1 from '../../../assets/Image/Bento/Conception/ConceptionPage1.png'
import conceptionPage2 from '../../../assets/Image/Bento/Conception/ConceptionPage2.png'
import { useResponsive } from '../../../utils/hooks'

/** CSS Styled Components **/

/**
 * Container for the Conception component.
 * @component
 */
const ConceptionContainer = styled.div`
  border-radius: 2rem;
  background: ${colorsToken.surface.Invert};
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 2.5rem;
  gap: 1rem;
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
`

/**
 * Container for the text elements within the Conception component.
 * @component
 */
const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
`

/**
 * Container for the images within the Conception component.
 * @component
 */
const ImageContainer = styled.div`
  flex: 2;
  position: relative;
`

/**
 * Returns responsive styling for images based on the device state and position properties.
 * @param {string} deviceState - The current device state (phone, small, big).
 * @param {number} right - Right position percentage.
 * @param {number} bottom - Bottom position percentage.
 * @param {number} left - Left position percentage.
 * @param {number} top - Top position percentage.
 * @returns {string|null} The CSS styles based on the device state.
 */
const getImageResponsive = (deviceState, right, bottom, left, top) => {
  switch (deviceState) {
    case 'phone':
      return ``
    case 'small':
      return `
        max-width: 20rem;
        right: ${right}%;
        bottom: ${bottom}%;
        left: ${left}%;
        top: ${top}%;
      `
    case 'big':
      return `
        max-width: 21rem;
        right: ${right}%;
        bottom: ${bottom}%;
        left: ${left}%;
        top: ${top}%;
      `
    default:
      return null
  }
}

/**
 * Styled image component with responsive and hover effects.
 * @component
 */
const Image = styled.img`
  width: 100%;
  height: fit-content;
  position: absolute;
  transform: rotate(${(props) => props.rotate});
  border-radius: 0.5rem;
  border: 1px solid ${colorsToken.bordure.Primary};
  filter: drop-shadow(3px 4px 11px rgba(20, 31, 26, 0.14))
    drop-shadow(14px 15px 20px rgba(20, 31, 26, 0.12))
    drop-shadow(30px 33px 27px rgba(20, 31, 26, 0.07))
    drop-shadow(54px 59px 32px rgba(20, 31, 26, 0.02))
    drop-shadow(84px 92px 35px rgba(143, 70, 35, 0));
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${ConceptionContainer}:hover & {
    transform: rotate(${(props) => props.rotateHover});
  }

  ${(props) =>
    getImageResponsive(
      props.devicestate,
      props.right,
      props.bottom,
      props.left,
      props.top
    )}
`

/**
 * Conception component that displays wireframes, mockups, and prototypes information
 * with responsive images.
 * @component
 */
function Conception() {
  const deviceState = useResponsive()

  return (
    <ConceptionContainer>
      <TextContainer>
        <Font.Title.TitreL
          style={{ margin: '0', color: colorsToken.text.Invert }}
        >
          Conception
        </Font.Title.TitreL>
        <Font.Text.TextM
          style={{ margin: '0', color: colorsToken.text.Invert }}
        >
          Réalisation de wireframes, maquettes et prototypes fonctionnels avec
          Figma.
        </Font.Text.TextM>
      </TextContainer>
      <ImageContainer>
        <Image
          devicestate={deviceState}
          src={conceptionPage2}
          top="-20"
          right="0"
          rotate="-5deg"
          rotateHover="-15deg"
        />
        <Image
          devicestate={deviceState}
          src={conceptionPage1}
          top="-5"
          right="5"
          rotate="-2deg"
          rotateHover="-7deg"
        />
      </ImageContainer>
    </ConceptionContainer>
  )
}

export default Conception
