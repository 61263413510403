import styled from 'styled-components'
import { colorsToken } from '../../utils/style/colors'
import { Font } from '../../utils/style/Font'
import ButtonContact from '../ButtonContact'
import gridPattern from '../../assets/Image/Footer/Grille.svg'

/**
 * Styled component for the footer container.
 * Sets up the main layout and background effects.
 */
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #040404;
  height: 28.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.1;
  }

  &:before {
    background: #000000;
    filter: url(#noiseFilter);
  }
`

/**
 * NoiseFilter component for creating a noise effect in the background.
 */
const NoiseFilter = () => (
  <svg width="0" height="0">
    <filter id="noiseFilter">
      <feTurbulence
        type="fractalNoise"
        baseFrequency="0.1"
        stitchTiles="stitch"
      />
      <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
      <feBlend in="SourceGraphic" in2="monoNoise" mode="screen" />
    </filter>
  </svg>
)

/**
 * Pattern component for creating a background pattern using an image.
 */
const Pattern = () => (
  <svg width="100%" height="100%" style={{ position: 'absolute' }}>
    <defs>
      <pattern
        id="pattern"
        patternUnits="userSpaceOnUse"
        width="19.25rem"
        height="19.25rem"
      >
        <image
          opacity="0.8"
          href={gridPattern}
          alt=""
          x="0"
          y="0"
          width="19.25rem"
          height="19.25rem"
        />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#pattern)" />
  </svg>
)

/**
 * Styled component for text in the footer.
 */
const Text = styled(Font.Text.TextM)`
  color: ${colorsToken.text.Invert};
  text-align: center;
`

/**
 * Styled component for the title in the footer.
 */
const Title = styled(Font.Title.TitreL)`
  color: ${colorsToken.text.Invert};
  text-align: center;
  margin: 0;
`

/**
 * Styled component for the footer bottom text.
 */
const BottomText = styled.span`
  color: ${colorsToken.text.Invert};
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.4rem */
  margin: 0 2rem;
  height: fit-content;
`

/**
 * Styled component for the content container in the footer.
 * Includes a bottom border for separation.
 */
const ContentContainer = styled.div`
  border-bottom: 1px solid ${colorsToken.text.Invert};
`

/**
 * Styled component for the main content in the footer.
 */
const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  top: 7.5rem;
  gap: 2rem;
`

/**
 * Styled component for the bottom section of the footer.
 */
const Bottom = styled.div`
  display: flex;
  width: 100%;
  max-width: 78rem;
  height: 5.4rem;
  align-items: center;
`

/**
 * Footer component to display the footer section of the webpage.
 * Includes noise and pattern background, text, and a button for contact.
 * @returns {JSX.Element} The Footer component.
 */
function Footer() {
  return (
    <FooterContainer>
      <NoiseFilter />
      <Pattern />
      <ContentContainer
        style={{ position: 'relative', height: '100%', width: '100%' }}
      >
        <Content>
          <Text>Vous avez un projet ?</Text>
          <Title>Travaillons ensemble !</Title>
          <ButtonContact text="Contactez moi !" theme="big" />
        </Content>
      </ContentContainer>
      <Bottom>
        <BottomText>© Josselin Le Bihan - UX/UI Designer à Paris</BottomText>
      </Bottom>
    </FooterContainer>
  )
}

export default Footer
