import { Font } from '../../utils/style/Font'
import styled from 'styled-components'
import BoutonDecouvrir from '../BoutonDecouvrir'
import { colorsToken } from '../../utils/style/colors'
import { Tags } from '../../utils/style/Atoms'
import { useResponsive } from '../../utils/hooks'
import PropTypes from 'prop-types'

const ProjetContainer = styled.div`
  z-index: 1;
  display: flex;
  gap: 4rem;
  padding: 2rem;
  flex-direction: ${({ deviceState }) =>
    deviceState !== 'big' ? 'column' : 'row'};
  height: ${({ deviceState }) => (deviceState !== 'big' ? '40.6rem' : '23rem')};
`

const Image = styled.div`
  width: 100%;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  border-radius: 1rem;
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(20, 31, 26, 0.01),
    19px 47px 31px 0px rgba(20, 31, 26, 0.05),
    8px 21px 23px 0px rgba(20, 31, 26, 0.09),
    2px 5px 12px 0px rgba(20, 31, 26, 0.1);
  height: ${({ deviceState }) =>
    deviceState !== 'big' ? '23.23831rem' : 'auto'};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const Titre = styled(Font.Title.TitreL)`
  color: ${colorsToken.text.Invert};
  width: 100%;
  margin: 0;
  text-transform: none;
`

const Date = styled(Font.Text.TextL)`
  color: ${colorsToken.text.Invert};
`

const Text = styled(Font.Text.TextM)`
  color: ${colorsToken.text.Invert};
`

const TagContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
`

const Right = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`

/**
 * PartB component renders the content and tags of a project.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered PartB component.
 */
const PartB = ({ titre, text, date, tags, id }) => {
  return (
    <Right>
      <Content>
        <TitleContainer>
          <Titre>{titre}</Titre>
          <Date>{date}</Date>
        </TitleContainer>
        <TagContainer>
          {tags && tags.length > 0 ? (
            tags.map((tag, index) => <Tags key={index} text={tag} />)
          ) : (
            <p>No tags available</p>
          )}
        </TagContainer>
        <Text>{text}</Text>
      </Content>
      <BoutonDecouvrir projectId={id} />
    </Right>
  )
}

PartB.propTypes = {
  titre: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
}

PartB.defaultProps = {
  tags: [],
}

/**
 * Projet component renders a project with title, description, date, and an image.
 * Adjusts layout based on device state and direction prop.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered Projet component.
 */
function Projet({ titre, text, date, direction, tags, image, id }) {
  const deviceState = useResponsive()

  return (
    <ProjetContainer deviceState={deviceState} id={id}>
      {direction === 'right' || deviceState !== 'big' ? (
        <>
          <Image image={image} deviceState={deviceState} />
          <PartB titre={titre} text={text} date={date} tags={tags} id={id} />
        </>
      ) : (
        <>
          <PartB titre={titre} text={text} date={date} tags={tags} id={id} />
          <Image image={image} deviceState={deviceState} />
        </>
      )}
    </ProjetContainer>
  )
}

Projet.propTypes = {
  titre: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['right', 'left']).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

Projet.defaultProps = {
  tags: [],
}

export default Projet
