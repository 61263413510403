import styled from 'styled-components'

const TitreL = styled.h1`
  leading-trim: both;
  text-edge: cap;

  /* Titre/Titre L */
  font-family: Lokanova, Std;
  font-size: 3rem;
  font-style: Std;
  font-weight: 400;
  line-height: 80%; /* 38.4px */
  letter-spacing: 1px;
  text-transform: uppercase;
`
const TitreM = styled.h2`
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'calt' off;

  /* Titre/Titre M */
  font-family: Lokanova;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 51.2px */
  letter-spacing: 0.5px;
`
const TitreS = styled.h3`
  leading-trim: both;
  text-edge: cap;

  /* Titre/Titre S */
  font-family: Lokanova;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
`
const TextL = styled.span`
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'calt' off;

  /* Texte/Texte L */
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 35.2px */
  letter-spacing: -0.22px;
`
const TextM = styled.span`
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'calt' off;

  /* Texte/Texte M */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 22.4px */
  letter-spacing: -0.16px;
`

const TextS = styled.span`
  color: var(--Text-Text-primary, #1b181c);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'calt' off;

  /* Texte/Texte S */
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.03125rem */
`

const TextBouton = styled.span`
  color: var(--Text-Text-primary, #1b181c);
  font-feature-settings: 'calt' off;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.01rem;
`

const Title = {
  TitreL,
  TitreM,
  TitreS,
}

const Text = {
  TextL,
  TextM,
  TextS,
  TextBouton,
}

export const Font = {
  Title,
  Text,
}
