import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
import { projectList } from '../../contents/projectList'
import { colorsToken } from '../../utils/style/colors'
import { Font } from '../../utils/style/Font'
import { Tags } from '../../utils/style/Atoms'
import iconArrow from '../../assets/Image/Icon/IconArrow.svg'
import iconInfo from '../../assets/Image/Icon/IconInfo.svg'
import { useContext } from 'react'
import { RefContext } from '../../utils/context'
import { useResponsive } from '../../utils/hooks'

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  padding-top: 9.5rem;
  justify-content: center;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 7rem;


  background-color: #ffffe6;
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
  }

  &:before {
    background: #F0DBA5;
    filter: url(#noiseFilter);
  }
  }
`

const NoiseFilter = () => (
  <svg width="0" height="0">
    <filter id="noiseFilter">
      <feTurbulence
        type="fractalNoise"
        baseFrequency="0.5"
        stitchTiles="stitch"
      />
      <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
      <feBlend in="SourceGraphic" in2="monoNoise" mode="screen" />
    </filter>
  </svg>
)

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 46rem;
`

const Title = styled(Font.Title.TitreL)`
  color: ${colorsToken.text.Brand};
  margin: 0;
  margin-bottom: 1rem;
  line-height: 110%;
`

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 2.5rem;
`

const BoldText = styled(Font.Text.TextM)`
  font-weight: 700;
`

const H1 = styled(Font.Title.TitreM)`
  margin: 0;
  margin-top: 1rem;
  color: ${colorsToken.text.Brand};
`

const H2 = styled(Font.Title.TitreS)`
  margin: 0;
  margin-bottom: 0.5rem;
`

const Img = styled.img`
  width: 100%;
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
  border-radius: 1.5rem;
  margin-bottom: 5rem;
  margin-top: 1rem;
`

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  cursor: pointer;
`

const BackIcon = styled.img`
  transform: rotate(180deg);
`

const StyledLink = styled(BoldText)`
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  transition: color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    color: ${colorsToken.text.Brand};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0%;
    height: 2px;
    background-color: ${colorsToken.text.Brand};
    transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
      left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &:hover:after {
    width: 100%;
    left: 0%;
  }
`

const TOCContainer = styled.div`
  position: fixed;
  top: 9.5rem;
  right: ${({ devicestate }) => (devicestate === 'big' ? `4rem` : '0.8rem')};
  border-radius: 1rem;
  background: ${({ visibility }) =>
    visibility ? colorsToken.surface.Primary : 'none'};

  padding: ${({ visibility }) => (visibility ? '1rem 2rem' : '1rem 0 0 1rem')};
  box-shadow: ${({ visibility }) =>
    visibility
      ? `53px 131px 40px 0px rgba(122, 122, 102, 0), 34px 84px 36px 0px rgba(122, 122, 102, 0.01), 19px 47px 31px 0px rgba(122, 122, 102, 0.05), 8px 21px 23px 0px rgba(122, 122, 102, 0.09), 2px 5px 12px 0px rgba(122, 122, 102, 0.1);`
      : 'none'};
`

const TOCLink = styled.a`
  display: block;
  margin: 0.5rem;
  text-decoration: none;
  color: ${({ active }) => (active ? colorsToken.text.Brand : '#7f7d74')};
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    color: ${colorsToken.text.Primary};
  }
`

const VisualLink = styled.div`
  height: 2px;
  width: ${({ size }) => (size === 'big' ? '16px' : '12px')};
  background-color: ${({ active }) =>
    active ? colorsToken.text.Brand : '#7f7d74'};
  border-radius: 2px;
  margin-left: 0px;
  margin-top: 1rem;
  transition: background 0.2s, box-shadow 0.2s;
`

const BlockQuoteContainer = styled.div`
  display: flex;
  border-radius: 0.5rem;
  background: ${colorsToken.surface.Invert};
  gap: 0.5rem;
  padding: 1rem;
  height: fit-content;

  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  align-items: flex-start;

  h1,
  h2,
  p,
  span,
  ol,
  li {
    color: ${colorsToken.text.Invert};
  }
`

const BlockQuote = ({ children }) => (
  <BlockQuoteContainer>
    <img src={iconInfo} alt="info" style={{ width: '1.5rem' }} />
    <div>{children}</div>
  </BlockQuoteContainer>
)

// Component Functions
const BackButton = ({ onClick }) => (
  <BackButtonContainer onClick={onClick}>
    <BackIcon src={iconArrow} alt="Back" />
    <StyledLink>Retour</StyledLink>
  </BackButtonContainer>
)

const TocVisualLink = ({ level }) => (
  <VisualLink size={level === 1 ? 'big' : 'small'} />
)

function ProjetPage() {
  const [toc, setToc] = useState([])
  const [projectContent, setProjectContent] = useState('')
  const deviceState = useResponsive()
  const [tocVisibility, setTocVisibility] = useState(false)
  const [activeSection, setActiveSection] = useState(null)
  const activeSectionRef = useRef(null)
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { setPage } = useContext(RefContext)
  setPage('Projet')

  //Réccupération des données du projet
  const projectFinder = (id) => {
    const project = projectList.find((project) => project.id === id)
    return project
  }
  const project = projectFinder(projectId)

  //réccupération du contenue du projet

  useEffect(() => {
    if (!project) return

    import(`../../contents/${project.id}.md`)
      .then((res) => fetch(res.default))
      .then((response) => response.text())
      .then((markdown) => {
        setProjectContent(markdown)
        generateTOC(markdown)
      })
      .catch(console.error)
  }, [project])

  //Création d'une table des matières dynamique
  const generateTOC = (markdown) => {
    const removeAccents = (str) =>
      str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    const tocArray = markdown.split('\n').reduce((acc, line) => {
      let text, id
      if (line.startsWith('# ')) {
        text = line.replace('# ', '').trim() // Supprimer les espaces
        id = `section-${removeAccents(text).replace(/\s+/g, '-').toLowerCase()}`
        acc.push({ level: 1, text, id })
      } else if (line.startsWith('## ')) {
        text = line.replace('## ', '').trim() // Supprimer les espaces
        id = `section-${removeAccents(text).replace(/\s+/g, '-').toLowerCase()}`
        acc.push({ level: 2, text, id })
      }
      return acc
    }, [])
    setToc(tocArray)
  }

  useEffect(() => {
    const sections = document.querySelectorAll('h1, h2')
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id // ID des éléments H1 et H2
            const tocId = `#${sectionId}` // Ajouter le # pour correspondre à l'ID du TOC
            console.log(
              'Active section reff current: ' +
                activeSectionRef.current +
                ' tocID : ' +
                tocId
            )

            if (activeSectionRef.current !== tocId) {
              activeSectionRef.current = tocId
              setActiveSection(tocId)
            }
          }
        })
      },
      { threshold: 0.6 } // Ajustez le seuil selon vos besoins
    )
    sections.forEach((section) => observer.observe(section))
    return () => sections.forEach((section) => observer.unobserve(section))
  }, [])

  const handleLinkClick = (id) => {
    setTimeout(() => {
      console.log(id)
      const element = document.getElementById(id.replace('section-', ''))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 0)
  }
  const handdleBackClick = () => {
    navigate('/') // Navigue vers la page home
    setTimeout(() => {
      const section = document.getElementById(projectId)
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 100) // Timeout pour s'assurer que la page est complètement chargée
  }

  const markdownOptions = {
    overrides: {
      h1: {
        component: ({ children, ...props }) => {
          return (
            <H1 id={props.id} {...props}>
              {children}
            </H1>
          )
        },
      },
      h2: {
        component: ({ children, ...props }) => {
          return (
            <H2 id={props.id} {...props}>
              {children}
            </H2>
          )
        },
      },
      p: {
        component: ({ children }) => (
          <p
            style={{
              whiteSpace: 'pre-wrap',
              fontSize: '1rem',
              lineHeight: '170%',
              marginTop: '0',
            }}
          >
            {children}
          </p>
        ),
      },
      span: { component: Font.Text.TextM },
      blockquote: { component: BlockQuote },
      li: {
        component: ({ children }) => (
          <li
            style={{
              fontFamily: 'Inter',
              fontSize: '1rem',
              lineHeight: '170%',
              letterSpacing: '-0.16px',
            }}
          >
            {children}
          </li>
        ),
      },
      img: {
        component: (props) => <Img {...props} />,
      },
    },
  }

  if (!project) {
    return (
      <Container>
        <h1>Projet non trouvé</h1>
      </Container>
    )
  }
  return (
    <Container>
      <NoiseFilter />
      <Content>
        <BackButton onClick={handdleBackClick} />
        <Title>{project.title}</Title>
        <TagContainer>
          {project.tags ? (
            project.tags.map((tag, index) => <Tags key={index} text={tag} />)
          ) : (
            <p>No tags available</p> // ou tout autre rendu alternatif souhaité
          )}
        </TagContainer>
        <DataContainer>
          <Font.Text.TextM>
            Date : <BoldText>{project.date}</BoldText>
          </Font.Text.TextM>
          <Font.Text.TextM>
            Durée : <BoldText>{project.duration}</BoldText>
          </Font.Text.TextM>
          <Font.Text.TextM>
            Equipe : <BoldText>{project.crew}</BoldText>
          </Font.Text.TextM>
        </DataContainer>
        <TOCContainer
          devicestate={deviceState}
          onMouseEnter={() => setTocVisibility(true)}
          onMouseLeave={() => setTocVisibility(false)}
          visibility={tocVisibility}
        >
          {tocVisibility
            ? toc.map((item, index) => (
                <TOCLink
                  key={index}
                  href={`#${item.id}`} // ID du TOC avec #
                  style={{ marginLeft: `${item.level - 1}rem` }}
                  onClick={() => handleLinkClick(item.id)}
                  active={`#${item.id}` === activeSection} // Ajouter le # pour la comparaison
                >
                  {item.text}
                </TOCLink>
              ))
            : toc.map((item, index) => (
                <TocVisualLink
                  key={index}
                  level={item.level}
                  active={`#${item.id}` === activeSection} // Ajouter le # pour la comparaison
                />
              ))}
        </TOCContainer>
        <Markdown options={markdownOptions}>{projectContent}</Markdown>
      </Content>
    </Container>
  )
}

export default ProjetPage
