import React, { createContext, useRef } from 'react'
import { useState } from 'react'

export const RefContext = createContext(null)

export const RefProvider = ({ children }) => {
  const projetsRef = useRef(null)
  const accueilsRef = useRef(null)
  const bentoRef = useRef(null)
  const [page, setPage] = useState('Home')

  return (
    <RefContext.Provider
      value={{ projetsRef, accueilsRef, bentoRef, page, setPage }}
    >
      {children}
    </RefContext.Provider>
  )
}
