import styled from 'styled-components'
import { colorsToken } from '../../../utils/style/colors'
import { Font } from '../../../utils/style/Font'
import iconMail from '../../../assets/Image/Icon/IconContact.svg'
import iconLinkedin from '../../../assets/Image/Icon/IconLinkedin.svg'
import iconPhone from '../../../assets/Image/Icon/IconPhone.svg'
import { useState } from 'react'

/** Styled Components **/

/**
 * Container for the entire Contact component.
 * @component
 */
const ContactSection = styled.div`
  background: ${colorsToken.surface.SpecialRose};
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 2rem;
  padding: 2.5rem 0 2.5rem 2.5rem;
  height: 6.9rem;
  gap: 1rem;
  overflow: hidden;
  box-shadow: 53px 131px 40px 0px rgba(122, 122, 102, 0),
    34px 84px 36px 0px rgba(122, 122, 102, 0.01),
    19px 47px 31px 0px rgba(122, 122, 102, 0.05),
    8px 21px 23px 0px rgba(122, 122, 102, 0.09),
    2px 5px 12px 0px rgba(122, 122, 102, 0.1);
`

/**
 * Container for the contact action buttons.
 * @component
 * @param {Object} props - Component props.
 * @param {string|null} props.hoverState - Current hover state ('Mail', 'Phone', 'Linkedin', or null).
 */
const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1.37rem;
  gap: 0.5rem;
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${(props) => {
    switch (props.hoverState) {
      case 'Mail':
      case null:
        return `
          top: 50%;
          transform: translateY(-50%);
        `
      case 'Phone':
        return `
          top: 40%;
          transform: translateY(-50%);
        `
      case 'Linkedin':
        return `
          top: 60%;
          transform: translateY(-50%);
        `
      default:
        return null
    }
  }}
`

/**
 * Container for each individual icon button.
 * @component
 */
const IconButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10rem;
  display: flex;
  padding: 0.8rem;
  justify-content: center;
  align-items: center;
  background: ${colorsToken.surface.Primary};
  box-shadow: 130px 142.381px 54.167px 0px rgba(143, 70, 35, 0),
    83.571px 91.31px 49.524px 0px rgba(154, 45, 74, 0.02),
    46.429px 51.071px 41.786px 0px rgba(154, 45, 74, 0.07),
    21.667px 23.214px 30.952px 0px rgba(154, 45, 74, 0.12),
    4.643px 6.19px 17.024px 0px rgba(154, 45, 74, 0.14);
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background: ${colorsToken.surface.SpecialBleu};
  }
`

/**
 * Styled component for the icons within the icon buttons.
 * @component
 */
const IconImage = styled.img`
  width: 110%;
  height: 110%;
`

/** React Component **/

/**
 * Contact component that displays options for contacting via LinkedIn, Email, or Phone.
 * The icons shift position slightly on hover.
 * @component
 */
function Contact() {
  const [hoverState, setHoverState] = useState(null)

  return (
    <ContactSection>
      <Font.Title.TitreL style={{ margin: '0' }}>
        Me contacter
      </Font.Title.TitreL>
      <Font.Text.TextM style={{ width: '14rem' }}>
        Prêt à concrétiser vos idées ? Créons-les ensemble !
      </Font.Text.TextM>
      <ActionButtons hoverState={hoverState}>
        <IconButton
          onMouseEnter={() => setHoverState('Linkedin')}
          onMouseLeave={() => setHoverState(null)}
        >
          <IconImage src={iconLinkedin} alt="LinkedIn" />
        </IconButton>
        <IconButton
          onMouseEnter={() => setHoverState('Mail')}
          onMouseLeave={() => setHoverState(null)}
        >
          <IconImage src={iconMail} alt="Mail" />
        </IconButton>
        <IconButton
          onMouseEnter={() => setHoverState('Phone')}
          onMouseLeave={() => setHoverState(null)}
        >
          <IconImage src={iconPhone} alt="Phone" />
        </IconButton>
      </ActionButtons>
    </ContactSection>
  )
}

export default Contact
